.intro-img{
  a{
    display: block;
    margin-top: 2em;
  }
}

.cjt-imgs1{
	
	.columns{
		padding: 0 !important;
		transform: scale(1.2) rotate(-0.5deg);
		img{
			width: 100%;
			
			transition: all 1s;
			//transform: rotate(0.3deg);
			margin: 30% 0;
			box-shadow: -5px -2px 25px rgba(0, 0, 0, 0.1);
		}
		&:first-child{
			
			@media (max-width: 640px) {
    			margin-top:-70%;
    			z-index: 1;
  			}
		}
		&:nth-child(2){
			margin-top: 0.3rem;
			img{
				animation-delay: 0.3s;
			}
			@media (max-width: 640px) {
    			margin-top:-70%;
    			z-index: 2;
  			}
		}
		&:nth-child(3){
			margin-top: 0.6rem;
			img{
				animation-delay: 0.6s;
			}
			
		}
		&:nth-child(4){
			margin-top: 0.9rem;	
			img{
				animation-delay: 0.9s;
			}
			
		}
		
	}
}

.cjt-imgs2{
	overflow: visible;
	margin-bottom: -50%;
	@media (max-width: 640px) {
    			margin-bottom: -140%;
  			}
	.columns{
		padding: 0 !important;
		transform: scale(1.1);
		img{
			width: 100%;
			
			//transform: rotate(0.3deg);
			margin: 30% 0;
			box-shadow: -5px -2px 25px rgba(0, 0, 0, 0.1);
		}
		&:first-child{
			


		}
		&:nth-child(2){
			margin-top: 3rem;
			img{
				animation-delay: 0.5s;
			}

		}
		&:nth-child(3){
			margin-top: 1.6rem;
			img{
				animation-delay: 1s;
			}
			@media (max-width: 640px) {
    			margin-top:-120%;
    			margin-left:5%;
  			}
		}
		&:nth-child(4){
			margin-top: 5rem;	
			img{
				animation-delay: 1.5s;
			}
			@media (max-width: 640px) {
    			margin-top:-100%;
    			margin-left:-10%;
  			}
		}
		
	}
}
#cjt-intro-img{
	.bg-wrap .fixed-img{
		background-position:top;
	}
}
#cjt-intro{


}
#cjt-final-prototype{

background: #fff;
box-shadow: 0px -15px 10px rgba(0, 0, 0, 0.06);

}

#cjt-process{



}

.cjt-mockup{
	position: relative;
	margin: 5% 0 20%  0;
	@media (max-width: 640px) {
	    margin: 5% 0 35%  0;
	  }
}
.cjt-macbook{
	transform: scale(1.1);
  margin-left: -10px;
}
.cjt-ipad{
	position:absolute;
	z-index: 1;
	bottom: -40%;
	left: -15%;
	width: 50%;
	animation-delay: 0.25s;
}
.cjt-phones{
	position:absolute;
	z-index: 1;
	bottom: -35%;
	left: 20%;
	width: 60%;
	animation-delay: 0.5s;
}
.data-wrapper{
	margin:1em 0;
}
#cjt-results{
	
	.data-label{
		margin-left: 0.7em;
		@media (max-width: 640px) {
		    font-size: 1em;
		  }
	}
	.data-number{
		@media (max-width: 640px) {
		    font-size: 3.3em;
		    font-weight: 200;
		  }
	}
}

#why-info{
	img{
		margin: 1em  0;
	}
  .columns{
    p:last-child{
      margin-bottom: 0;
    }
  }
}



#boi-process{
  
  .row.expanded{
    margin: -7em -4.5em 0 -4.5em;
  }
  @media (max-width: 1028px) {
    .row.expanded{
      margin: -10% -4.5em 0 -4.5em;
    }
}
}

#boi-results{
  .final{
    
      margin-top: 2em;
    
  }
  .data-number{
    margin-top: 0.7em;
    
  }
  .data-number, .data-label{
    display: block;
  }
}

#abc-results{
  display: flex;

  .final{
    
      margin-top: 2em;
    
  }
  .data-number{
    margin-top: 1.3em;
    display: block;
    font-size: 2.1em;
    
  }
  .data-number, .data-label{
    display: block;
  }
}


.cpt-mockup{
      transform: rotate(-6deg) scale(1.3) translate(-17%, 13%);
}
.cpt-mockup-two{
  margin:-8em 0;
}
#cpt-results{
   .data-wrapper {
    position:relative;
    z-index: 111;
    display: block;
   }
   .data-number{
    display: block;
    font-size: 4.5em;
   }
   .data-label{
    font-size: 1.5em;
   }
}

.tsys-info{
  margin-top: 4em;
  margin-bottom: 4em;
}




#sisu-cases{
  h1{
    margin-bottom: 1.5em;
  }

}
.case{
  display: block;
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  height: 100%;



}

.case-thumb{
  display: block;
  padding-bottom:60%;
  margin-bottom: 1em;
  
  background-color:rgba(39,79,125,0.6);
  background-blend-mode: multiply;
  background-size:cover;

  
   transition: all 0.3s ease-in-out;
  
  position:relative;
  border-radius: 5px;
  &:after{
    //content: "";
    background: #274f7d;
    background-blend-mode: multiply;
    opacity: 0.7;
    position:absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;

   
  }
  &:hover{
    opacity: 0.65;  
    &:after{
      opacity: 0;
    }
  }
  &:before{
    //content: "";
    background: #274f7d;
    background-blend-mode: color;
    opacity: 0;
    position:absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 1;
  }

  @media screen and (max-width:630px) {
    margin-bottom: 0.5em;
    
  }
}
.case-title{
  color: $primary-color;
  margin-bottom: 0.3em;
  flex: 0 0 auto;
}
.case-description{
  color: $black;
  flex:1 0 auto;
}
.case-readmore{
  text-align: right;
  flex: 0 0 auto;
  font-size: 140%;
  font-weight: 700;
  text-transform: uppercase;
}

.cjt-thumb{
  
  background-image:url('../img/cases/cjt-thumb.jpg');
  

}
.boi-thumb{
  background-image:url('../img/cases/boi-thumb.jpg');
}
.abc-thumb{
  background-image:url('../img/cases/abc-thumb.jpg');
}
.tsy-thumb{
  background-image:url('../img/cases/tsy-thumb.jpg');
}
.cpt-thumb{
  background-image:url('../img/cases/cpt-thumb.jpg');
}
.cvt-thumb{
  background-image:url('../img/cases/cvt-thumb.jpg');
}
.bsp-thumb{
  background-image:url('../img/cases/bsp-thumb.jpg');
}
.seg-thumb{
  background-image:url('../img/cases/seg-thumb.jpg');
}


/* Zoom In #1 */
.case-thumb img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
.case-thumb:hover img {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}

.final-back{
  margin-top: 3em;
}



//CVT

.cvt-mockup{
  position: relative;
  width: 100%;
    transform: scale(1.6);
  
  .cvt-mash{
    padding-left: 10%;
    margin-top:15%;
  }

  .ipad, .iphone{
    position: absolute;
    width: 100%;
    left: 0;
  }
  .iphone{
    //top:-5%;
  }
}
.results-wrapper{
  position:relative;
  z-index:1;
}
.seg-mockup2{
   margin:15% 15% 30% -15%;
   position:relative;

  transform: scale(1.6);
}
#seg-results{
  
  .data-wrapper{
    margin-bottom:2em;
    
  }
  .data-number{
    display: block;
  }
  .data-label{
    display: block;
  }
  .results-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom:35%;
  }
}
.seg-mockup{
  position: relative;
  margin:3em 0;
  width: 100%;
   
  .mock{
    position:absolute;
    left: 0;
    top: 0;
  }
}

#cvt-results{
  .data-number{
    display: block;
  }
  .data-label{
    margin-bottom: 3em;
    display: block;
  }

  .cita-results{
    //margin-bottom: 5em;
    margin:3em 0;
  }
}

#bsp-results{
  .data-number{
    display: block;
  }
  .data-label{
    margin-bottom: 1em;
    display: block;
  }

  .cita-results{
    //margin-bottom: 5em;
    margin:3em 0;
  }
}

.bsp-mockup{
  position: relative;
  width: 100%;
    //transform: scale(1.6);
  
  .cvt-mash{
    //padding-left: 10%;
    //margin-top:15%;
  }

  .iphone2, .android{
    position: absolute;
    width: 100%;
    left: 0;
  }

  
}


#bsp-imgs{
  
  
  background-color:rgba(39,79,125,0.6);
  background-blend-mode: multiply;
  background-size:cover;

  
   
  
  position:relative;
  
  &:after{
    //content: "";
    background: #274f7d;
    background-blend-mode: multiply;
    opacity: 0.7;
    position:absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;

   
  }
 
  &:before{
    //content: "";
    background: #274f7d;
    background-blend-mode: color;
    opacity: 0;
    position:absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 1;
  }
}