:root {
  scroll-behavior: smooth;
}

.intro {
  position: relative;
  scroll-behavior: smooth;

  .scrolling-pill {
    position: absolute;
    bottom: 30px;
    border-radius: 50px;
    opacity: 0;
    left: 50%;
    margin-left: calc(-1 * calc(189px / 2));
    border: 2px solid;
    padding: 1em 30px 1em 20px;
    overflow: hidden;
    background: #fff !important;
    color: #37474f;
    color: #175298 !important;
    border-color: white;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s, translate 0.7s;

    animation: .7s ease-out 1s 1 slidein;

    @keyframes slidein {
      from {
        bottom: -20px;
        opacity: 0;
      }

      to {
        bottom: 30px;
        opacity: 1;
      }

    }

    opacity: 1;


    &:hover {
      translate: 0 -10px;
      color: #fff !important;
      background: #7986cb;
      background: #175298 !important;
      border-color: white !important;
      background: transparent !important;



    }


    .svg-inline--fa {
      display: var(--fa-display, inline-block);
      height: 1em;
      overflow: visible;
      vertical-align: -0.125em;
      left: 20px;
      font-size: 18px !important;
      transform: translate3d(0, 0, 0);
      margin-right: .7em;
    }

  }
}

.home {
  &__svg {
    svg {

      max-width: 188px;
      max-height: 270px;
    }
  }
}