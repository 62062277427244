.diag1-2 {
	font-family: Roboto, Arial, sans-serif;
}

html,
body {
	height: 100%;
}

p {
	line-height: 1.4;
}

.sticky-container {
	position: absolute;
	width: 100%;
	z-index: 1;
}

.menu>li>a {
	display: block;
	padding: 1rem 1rem;
	line-height: 1;

	&.active {
		font-weight: 700;
	}
}

ul.list {

	margin-left: 1.5em;

	li {
		margin-bottom: 1em;
	}
}

h1{
	span{
		display: block;
	}
}

// .noWrap {
// 	white-space: nowrap;
// }

section,
.section {
	padding: 100px 1em;
	overflow: hidden;
	background: white;
	position: relative;

	@media (max-width: 1024px) {
		padding: 2em 1em;
	}

	background-size: cover;
	background-position: bottom center;



}

.separator {
	width: 100%;
	border-top-width: 1px;
	border-top-style: solid;
	border-top-color: #C3C3C3;
	margin: 2.5em 0;

	&.white {
		border-top-color: white;
	}

	@media (max-width: 640px) {
		//margin: 1.5em 0;
	}
}

.mb-0 {
	margin-bottom: 0;
}

.mt-0 {
	margin-top: 0;
}

.mt-s-0 {
	@media (max-width: 640px) {
		margin-top: 0 !important;
	}
}

.p-0 {
	padding: 0;
}

.full-section {
	//height: 400px;
	min-height: 100vh;
	min-height: 100dvh;
	display: flex;
	align-items: center;
	box-sizing: border-box;

	.row {
		height: 100%;
	}

	.container {
		width: 100%;
	}
}

.half-section {
	//height: 400px;
	min-height: 50vh;
	display: flex;
	align-items: center;
	box-sizing: border-box;

	.row {
		height: 100%;
	}

	@media (max-width: 639px) {
		//min-height:100vh;
	}
}

.threequarter-section {
	//height: 400px;
	min-height: 75vh;
	display: flex;
	align-items: center;
	box-sizing: border-box;

	.row {
		height: 100%;
	}

	@media (max-width: 639px) {
		//min-height:100vh;
	}
}

.row {

	&::after,
	&::before {
		display: block;
	}

	&.expanded {
		width: 100%;
	}
}

.bg-wrap {
	clip: rect(0, auto, auto, 0);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: #375681;
}

.fixed-img {
	position: fixed;
	display: block;
	z-index: -1;
	top: 0;
	height: 100vh;
	width: 100%;
	background-size: cover;
	background-position: center;
	transform: translateZ(0);
}


.b-color {
	color: white;
	background-size: 100% 100%;
}

.b-gray {
	background: #f2f2f2;
}

.b-img {
	color: white;
	background: transparent;

	//padding:0;
	.container {
		padding: 100px 1em;
		height: 100%;
	}

	.is-invalid-label {
		color: black;
	}

	a {
		color: white;
	}

	b {
		a {
			color: black;
		}
	}
}

.fade-back {
	&:before {
		content: "";
		width: 100%;
		height: 30vh;
		position: absolute;
		top: 0;
		left: 0;

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00010b+0,00010f+100&1+0,0+100 */
		background: -moz-linear-gradient(top, rgba(10, 10, 30, 0.4) 0%, rgba(0, 1, 20, 0) 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(10, 10, 30, 0.4) 0%, rgba(0, 1, 20, 0) 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(10, 10, 30, 0.4) 0%, rgba(0, 1, 20, 0) 100%);
		/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00010b', endColorstr='#0000010f', GradientType=0);
		/* IE6-9 */

	}
}



.b-color {

	color: white;
	background-size: 100% 100%;

	cite {
		color: white;
	}
}

.b-blue {
	background: #395378 !important;
	color: white;

}

cite {
	margin-bottom: 1em;
	font-style: normal;
	color: black;

	@media (max-width:640px) {
		font-size: 1.4em;
		line-height: 1.2;
		margin-bottom: 1em;
		display: block;
	}

}

.cita-section {
	padding: 70px 1em;
	color: #333333;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e2e2e2+0,ffffff+29,ffffff+71,e2e2e2+100 */
	//background: rgb(226,226,226); /* Old browsers */
	//background: -moz-linear-gradient(top, rgba(240,240,250,1) 0%,rgba(245,245,255,1) 15%,rgba(235,240,255,1) 90%, rgba(230,235,250,1) 100%); /* FF3.6-15 */
	//background: -webkit-linear-gradient(top, rgba(240,240,250,1) 0%,rgba(245,245,255,1) 15%,rgba(235,240,255,1) 90%, rgba(230,235,250,1) 100%); /* Chrome10-25,Safari5.1-6 */
	//background: linear-gradient(to bottom, rgba(240,240,250,1) 0%,rgba(245,245,255,1) 15%,rgba(235,240,255,1) 90%, rgba(230,235,250,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}





.top-bar {
	background: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(10px);

	@media (max-width: 639px) {
		background: rgba(255, 255, 255, 0.7);
	}

	transition: all 0.5s;

	.top-bar-title {

		padding: 0.5rem 1rem;
	}

	.logo {
		background: url(../img/common/logo-color.png);
		background-size: contain;
		background-repeat: no-repeat;
		width: 100px;
		height: 0px;
		padding-bottom: 35%;
		transition: all 0.8s;
		color: transparent;
	}

	.menu-icon {
		margin: 20px;
		width: 20px;
		height: 16px;

		&::after {

			background: #000;
			box-shadow: 0 7px 0 #000, 0 14px 0 #000;

		}
	}

	padding:0;

	&.white {
		background: rgba(0, 0, 0, 0.3);

		@media (max-width: 639px) {
			background: rgba(0, 0, 0, 0.5);

		}

		li {
			a {
				color: white;
			}
		}

		&.is-anchored {
			background: transparent;
			backdrop-filter: none;

			.logo {

				width: 150px;

			}

			@media (max-width: 639px) {
				.top-bar-right {
					background: rgba(0, 0, 0, 0.5);
					padding-top: 110px;
					margin-top: -110px;
					z-index: -1;
					position: relative;
				}
			}
		}

		.logo {
			background: url(../img/common/logo-white.png);
			background-size: contain;
			background-repeat: no-repeat;
		}

		.menu-icon::after {

			background: #fefefe;
			box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;

		}


	}

	ul {
		background: transparent;
		text-align: right;

		@media (max-width: 639px) {
			font-size: 120%;

			li {
				display: block;
				text-align: center;
			}
		}

		li {


			a {
				color: black;

			}
		}
	}
}

.center-content {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	height: 100%;
	@extend .align-middle;

	svg {
		display: block;
		width: 100%;
	}
}



#trigger1,
#trigger2,
#trigger3 {
	position: absolute;
	top: 50%;

	width: 100%;

}

.relative {
	position: relative;
	z-index: 9999;
}

.back-btn {
	opacity: 0.8;
	width: auto;
	display: inline-block;
	transition: all 0.3s;

	&:hover {
		opacity: 1;
	}

	img {
		vertical-align: middle;
	}

	p {
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 0
	}

	a {
		margin: 0 !important;
	}

	margin-bottom: 1em;
}

.align-self-center {
	align-self: center;
}

.display-none {
	display: none;
}