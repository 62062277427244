#intro,
#work-intro,
#services-intro {

	@extend .fade-back;

}

#second {
	.fs-3 {
		padding-bottom: 1.2em;
	}
}

#third {}

#services {
	.row {
		padding: 2rem 0;
	}

	svg {
		padding: 2rem 0;
		display: block;
		margin-inline: auto;
	}
}

#cases {

	//padding-bottom: 0;
	.row {
		padding-top: 9em;

		@media (max-width:639px) {
			padding-top: 4em;
		}

		&.align-middle {
			padding-top: 4em;
		}
	}


}

#things {

	h2 {
		padding: 1em 0;
	}

	h3 {
		padding-bottom: 0.5em;

	}

	p {}

	p {
		padding-bottom: 2em;
		font-size: 1.5rem;

		@media (max-width:640px) {
			font-size: 1.3rem;
		}
	}
}

#clients {
	margin-bottom: 2em;
	min-height: initial;

	.fs-2 {
		margin-bottom: 1.5em;

		@media (max-width:639px) {
			margin-bottom: 1em;
		}
	}

	.logos {

		font-size: 0;
	}

	.logo-client {
		height: 0;


		margin: 4em 0px;

		display: inline-block;
		text-align: center;

		img {

			display: inline-block;

		}

		width: 33.3%;
		width: 50%;

		padding-bottom: 18%;
		padding-bottom: 30%;

		@media (min-width:640px) {
			width: 20%;
			padding-bottom: 10%;
		}
	}
}

#quote {
	color: white;

}

#contact {
	color: white;

	padding: 3rem;

	a {
		color: white;
		font-weight: bold;
		font-weight: 700;
	}

	p.contact-info {
		font-size: 1.8em;
		margin-top: 2em;

		@media (max-width: 639px) {

			font-size: 1.3em;

		}
	}

	background-attachment: initial;

}


#service-second {
	background: #f0f0f0;

	.fs-3 {
		margin-bottom: 1em;
	}

	.fs-4 {
		margin: 1.5em 0;
	}

	.fs-5 {
		//margin: 1.2em 0; 
	}

	.card {
		background: white;

		.medium-6 {
			padding: 3em;

			@media (max-width: 639px) {
				padding: 1em;
			}
		}

		.img {
			background-position: center;
			background-size: cover;
			display: flex;
			text-align: center;
			color: white;
			text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);

			@media (max-width: 639px) {
				padding: 35% 0;
			}
		}

		@media (max-width: 639px) {
			font-size: 150%;

		}

	}
}

#service-sprint {
	padding: 3em 0;

	.service {
		padding: 0.5em 0;
	}

	svg {
		padding: 0.5em 0;
	}

	@media (min-width: 639px) {
		padding: 10em 0;

		svg {
			padding: 3em 0;
		}
	}
}

#service-principles {
	.fs-2 {
		margin-bottom: 1em;
	}

	ul {
		text-align: left;

		li {
			position: relative;
			margin-bottom: 1em;
			vertical-align: middle;


			img {
				display: inline-block;
				margin-right: 20px;
				width: 50px;
				vertical-align: middle;
			}

			span {
				display: inline-block;
				width: calc(100% - 70px);
				vertical-align: middle;

			}
		}
	}
}

#service-whatWeDo {
	.fs-4 {
		margin-bottom: 1em;
	}

	.fs-6 {
		margin-bottom: 1em;
	}

	ul {}

	svg {
		padding: 3em 0 1em 0;
	}

	.columns {
		@media (max-width: 639px) {
			font-size: 180%;

			svg {
				padding: 1em 0 0.5em 0;
			}

			.fs-4 {
				font-size: 1em;
				font-weight: 500;
			}
		}

	}
}

#work-abc {
	.abc-stats {
		@media (min-width: 1024px) {
			position: absolute;
			right: 0;
			margin-top: 4rem;
		}

	}

	.cp-stats {}
}

.work {
	position: relative;

	background: transparent;

	&:before {
		content: "";
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100 */
		background: #f2f2f2;
		/* Old browsers */
		background: -moz-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #ffffff 0%, #f2f2f2 100%);
		/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0);
		/* IE6-9 */


		width: 100%;
		padding-bottom: 30%;
		position: absolute;
		z-index: -1;
		left: 0;
		bottom: 0;
	}

	img {
		margin: 1em 0;

		@media (min-width: 640px) {
			margin: 4rem 0;
		}
	}

	.fs-6 {
		margin-bottom: 2rem;

	}

	.second-title {
		padding-top: 2em;
	}

	@media (min-width: 640px) {
		font-size: 80%;
	}

	.logo-work {
		margin: 0;
	}
}

.stats {
	p {
		margin-bottom: 0;
	}

	margin-bottom: 2em;

}






.bcg {
	background-position: center center;
	background-repeat: no-repeat;

	background-size: cover;
	height: 100%;
	width: 100%;
}

#services {
	.columns {
		position: relative;

	}

}

.card-contact {
	display: flex;
	margin-top: 2em;
	border-radius: 5px;
	color: black;
	background-color: white;

	form {
		margin: 1.5em 1.5em 0.5em;
	}
}

.recaptcha-div {
	margin: 1.5em 0 2em;
	width: auto;
}

.recaptcha-err {
	color: brown;
}

.no-padding {
	padding: 0;
}

.no-margin {
	margin: 0;
}

.btn-send-contact {
	border-radius: 5px;
}

.section-contact {
	padding: 7em 0em;
}

.mt-05 {
	margin-top: 0.5em;
}

.g-recaptcha {
	margin: 1.5em 0 0.75em 0;
	width: 100% !important;
	overflow: visible;

	div {
		width: 100% !important;

		max-width: 100px;
		max-width: 69vw;
		overflow: visible;
	}

	iframe {
		transform-origin: 0 0;
	}
}

@media only screen and (max-width: 445px) {
	.g-recaptcha {
		div {
			height: 0 !important;
			padding-bottom: 25%;
		}

		iframe {
			transform: scale(0.85);
		}
	}
}

@media only screen and (max-width: 372px) {
	.g-recaptcha {
		iframe {
			transform: scale(0.7);
		}
	}
}

@media only screen and (max-width: 305px) {
	.g-recaptcha {
		iframe {
			transform: scale(0.6);
		}
	}
}

.underline-black {
	text-decoration: underline black;
}

.password-input {
	display: none !important;
}

.approach-svg{
	overflow: visible;
}

.roi-container{
	max-width: 1024px;
}