fieldset{
  width: 100%;
}


// inputs con label chevres
.inputcontainer {
position: relative;
padding-top: 1em;
margin-top:-1em;
//all textish inputs
input:not([type=submit]):not([type=file]),
textarea {
width: 100%;
overflow: hidden;
resize: none;
//make placeholder disappear on focus
&:focus::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0);
}
&:focus:-moz-placeholder {
  color: rgba(255, 255, 255, 0);
}
&:focus::-moz-placeholder {
  color: rgba(255, 255, 255, 0);
}
&:focus:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0);
}
& + label {
  
  
}
&:focus, &:not(:empty), &:not(:placeholder-shown) {
  & + label {
    z-index:1;
    transform: translateY(-80%);
    color: #8a8a8a;
    background:white;
  }
}
}
label {
//position:absolute;
display:inline-block;
top: 23px;
left:1rem;
z-index:-1;
font-size: 1em;
margin-left: -6px;
padding: {
  left:6px;
  right:6px;      
}
color: #000;
transition: all 200ms;
    white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 95%;
} 
}

fieldset{
legend{
padding: 1rem 0;
}
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
height: 3rem;   
padding: 0.7rem;
appeareance:none;
background:#FAFAFA !important;
border-radius: 5px;
}

.recover-mail{
  height: 2.4375rem;
padding: 0.5rem;
}

textarea::placeholder {
  color: #777;  
}

.form-contact{
margin-top: 3em;
}
/*
.file-upload {
position: relative;
display: inline-block;
}
*/
.file-upload__label {

/*
display: block;
padding: 1em 2em;
color: #fff;
background: #222;
border-radius: .4em;
transition: background .3s;

&:hover {
 cursor: pointer;
 background: #000;
}*/
}

.file-upload__input {
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
font-size: 1;
width:0;
height: 100%;
opacity: 0;
}


//checkboxes


.checkbox input[type="checkbox"] {
opacity: 0;
display: none;
}

.checkbox label {
position: relative;
display: inline-block;
line-height: 28px;
margin-right: 0;
/*16px width of fake checkbox + 6px distance between fake checkbox and text*/
padding-left: 34px;
margin:0;
}

@media only screen and (max-width: 961px) {
	.checkbox label {
    line-height: 18px;
  }
}

.checkbox label::before,
.checkbox label::after {
position: absolute;
content: "";

/*Needed for the line-height to take effect*/
display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before{
height: 24px;
width: 24px;
border-radius: 2px;

border: 1px solid rgb(202, 202, 202);
left: 0px;

/*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
 *to vertically center it.
 */
top: 3px;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
height: 6px;
width: 15px;
border-left: 2px solid;
border-bottom: 2px solid;

transform: rotate(-45deg);

left: 4px;
top: 10px;
}

/*Hide the checkmark by default*/
.checkbox input[type="checkbox"] + label::after {
content: none;
}

/*Unhide on the checked state*/
.checkbox input[type="checkbox"]:checked + label::after {
content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox input[type="checkbox"]:focus + label::before {
//outline: rgb(59, 153, 252) auto 5px;
}

.button{
text-transform: uppercase;
}





/* Common button styles */
.button {
/*float: left;*/
min-width: 150px;
/*max-width: 250px;*/
display: block;
margin: 1em;
padding: 1em 2em;
border: none;
background: none;
color: inherit;
vertical-align: middle;
position: relative;
/*z-index: 1;*/
-webkit-backface-visibility: hidden;
-moz-osx-font-smoothing: grayscale;
}
.button:focus {
outline: none;
}
.button > span {
vertical-align: middle;
}


/* Sizes */
.button--size-s {
font-size: 14px;
}
.button--size-m {
font-size: 16px;
}
.button--size-l {
font-size: 18px;
}

/* Typography and Roundedness */
.button--text-upper {
letter-spacing: 2px;
text-transform: uppercase;
}
.button--text-thin {
font-weight: 300;
}
.button--text-medium {
font-weight: 500;
}
.button--text-thick {
font-weight: 600;
}
.button--round-s {
border-radius: 5px;
}
.button--round-m {
border-radius: 15px;
}
.button--round-l {
border-radius: 40px;
}

/* Borders */
.button--border-thin {
border: 1px solid !important;
}
.button--border-medium {
border: 2px solid !important;
}
.button--border-thick {
border: 3px solid !important;
}

/* Individual button styles */

/* Winona */
.button--winona {
overflow: hidden;
padding: 0;
-webkit-transition: border-color 0.3s, background-color 0.3s;
transition: border-color 0.3s, background-color 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--winona::after {
content: attr(data-text);
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
opacity: 0;
color: #3f51b5;
-webkit-transform: translate3d(0, 25%, 0);
transform: translate3d(0, 25%, 0);
}
.button--winona > span {
display: block;
}
.button--winona.button--inverted {
color: #7986cb;
}
.button--winona.button--inverted:after {
color: #fff;
}
.button--winona::after,
.button--winona > span {
padding: 1em 2em;
-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
transition: transform 0.3s, opacity 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--winona:hover {
border-color: #3f51b5;
background-color: rgba(63, 81, 181, 0.1);
}
.button--winona.button--inverted:hover {
border-color: #21333C;
background-color: #21333C;
}
.button--winona:hover::after {
opacity: 1;
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
.button--winona:hover > span {
opacity: 0;
-webkit-transform: translate3d(0, -25%, 0);
transform: translate3d(0, -25%, 0);
}

/* Ujarak */
.button--ujarak {
-webkit-transition: border-color 0.4s, color 0.4s;
transition: border-color 0.4s, color 0.4s;
}
.button--ujarak::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: #37474f;
z-index: -1;
opacity: 0;
-webkit-transform: scale3d(0.7, 1, 1);
transform: scale3d(0.7, 1, 1);
-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
transition: transform 0.4s, opacity 0.4s;
-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--ujarak.button--round-s::before {
border-radius: 2px;
}
.button--ujarak.button--inverted::before {
background: #7986CB;
}
.button--ujarak,
.button--ujarak::before {
-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--ujarak:hover {
color: #fff;
border-color: #37474f;
}
.button--ujarak.button--inverted:hover {
color: #37474F;
border-color: #fff;
}
.button--ujarak:hover::before {
opacity: 1;
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}

/* Wayra */
.button--wayra {
overflow: hidden;
width: 245px;
-webkit-transition: border-color 0.3s, color 0.3s;
transition: border-color 0.3s, color 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--wayra::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 150%;
height: 100%;
background: #37474f;
z-index: -1;
-webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
-webkit-transform-origin: 0% 100%;
transform-origin: 0% 100%;
-webkit-transition: -webkit-transform 0.3s, opacity 0.3s, background-color 0.3s;
transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}
.button--wayra:hover {
color: #fff;
border-color: #3f51b5;
}
.button--wayra.button--inverted:hover {
color: #3f51b5;
border-color: #fff;
}
.button--wayra:hover::before {
opacity: 1;
background-color: #3f51b5;
-webkit-transform: rotate3d(0, 0, 1, 0deg);
transform: rotate3d(0, 0, 1, 0deg);
-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--wayra.button--inverted:hover::before {
background-color: #fff;
}

/* Tamaya */
.button--tamaya {
overflow: hidden;
color: #7986cb;
min-width: 180px;
}
.button--tamaya.button--inverted {
color: #37474f;
border-color: #37474f;
}
.button--tamaya::before,
.button--tamaya::after {
content: attr(data-text);
position: absolute;
width: 100%;
height: 50%;
left: 0;
background: #7986cb;
color: #fff;
overflow: hidden;
-webkit-transition: -webkit-transform 0.3s;
transition: transform 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--tamaya.button--inverted::before,
.button--tamaya.button--inverted::after {
background: #fff;
color: #37474f;
}
.button--tamaya::before {
top: 0;
padding-top: 1em;
}
.button--tamaya::after {
bottom: 0;
line-height: 0;
}
.button--tamaya > span {
display: block;
-webkit-transform: scale3d(0.2, 0.2, 1);
transform: scale3d(0.2, 0.2, 1);
opacity: 0;
-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
transition: transform 0.3s, opacity 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--tamaya:hover::before {
-webkit-transform: translate3d(0, -100%, 0);
transform: translate3d(0, -100%, 0);
}
.button--tamaya:hover::after {
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
}
.button--tamaya:hover > span {
opacity: 1;
-webkit-transform: scale3d(1, 1, 1);
transform: scale3d(1, 1, 1);
}

/* Rayen */
.button--rayen {
overflow: hidden;
padding: 0;
width: 230px;
}
.button--rayen.button--inverted {
color: #fff;
}
.button--rayen::before {
content: attr(data-text);
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: #7986cb;
-webkit-transform: translate3d(-100%, 0, 0);
transform: translate3d(-100%, 0, 0);
}
.button--rayen.button--inverted::before {
background: #fff;
color: #37474f;
}
.button--rayen > span {
display: block;
}
.button--rayen::before,
.button--rayen > span {
padding: 1em 2em;
-webkit-transition: -webkit-transform 0.3s;
transition: transform 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--rayen:hover::before {
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
.button--rayen:hover > span {
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
}

/* Pipaluk */
.button--pipaluk {
width: 240px;
padding: 1.5em 3em;
color: #fff;
}
.button--pipaluk.button--inverted {
color: #7986cb;
}
.button--pipaluk::before,
.button--pipaluk::after {
content: '';
border-radius: inherit;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: -1;
-webkit-transition: -webkit-transform 0.3s, background-color 0.3s;
transition: transform 0.3s, background-color 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.25, 0, 0.3, 1);
transition-timing-function: cubic-bezier(0.25, 0, 0.3, 1);
}
.button--pipaluk::before {
border: 2px solid #7986cb;
}
.button--pipaluk.button--inverted::before {
border-color: #fff;
}
.button--pipaluk::after {
background: #7986cb;
}
.button--pipaluk.button--inverted::after {
background: #fff;
}
.button--pipaluk:hover::before {
-webkit-transform: scale3d(1, 1, 1);
transform: scale3d(1, 1, 1);
}
.button--pipaluk::before,
.button--pipaluk:hover::after {
-webkit-transform: scale3d(0.7, 0.7, 1);
transform: scale3d(0.7, 0.7, 1);
}
.button--pipaluk:hover::after {
background-color: #3f51b5;
}
.button--pipaluk.button--inverted:hover::after {
background-color: #fff;
}

/* Nuka */
.button--nuka {
margin: 1em 2em;
-webkit-transition: color 0.3s;
transition: color 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--nuka.button--inverted {
color: #37474f;
}
.button--nuka::before,
.button--nuka::after {
content: '';
position: absolute;
border-radius: inherit;
background: #7986cb;
z-index: -1;
}
.button--nuka::before {
top: -4px;
bottom: -4px;
left: -4px;
right: -4px;
opacity: 0.2;
-webkit-transform: scale3d(0.7, 1, 1);
transform: scale3d(0.7, 1, 1);
-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
transition: transform 0.3s, opacity 0.3s;
}
.button--nuka::after {
top: 0;
left: 0;
width: 100%;
height: 100%;
-webkit-transform: scale3d(1.1, 1, 1);
transform: scale3d(1.1, 1, 1);
-webkit-transition: -webkit-transform 0.3s, background-color 0.3s;
transition: transform 0.3s, background-color 0.3s;
}
.button--nuka::before,
.button--nuka::after {
-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--nuka.button--inverted::before,
.button--nuka.button--inverted::after {
background: #fff;
}
.button--nuka:hover {
color: #fff;
}
.button--nuka:hover::before {
opacity: 1;
}
.button--nuka:hover::after {
background-color: #37474f;
}
.button--nuka.button--inverted:hover::after {
background-color: #7986cb;
}
.button--nuka:hover::after,
.button--nuka:hover::before {
-webkit-transform: scale3d(1, 1, 1);
transform: scale3d(1, 1, 1);
}

/* Moema */
.button--moema {
padding: 1.5em 3em;
border-radius: 50px;
background: #7986cb;
color: #fff;
-webkit-transition: background-color 0.3s, color 0.3s;
transition: background-color 0.3s, color 0.3s;
}
.button--moema.button--inverted {
background: #ECEFF1;
color: #37474f;
}
.button--moema::before {
content: '';
position: absolute;
top: -20px;
left: -20px;
bottom: -20px;
right: -20px;
background: inherit;
border-radius: 50px;
z-index: -1;
opacity: 0.4;
-webkit-transform: scale3d(0.8, 0.5, 1);
transform: scale3d(0.8, 0.5, 1);
}
.button--moema:hover {
-webkit-transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
color: #ECEFF1;
background-color: #3f51b5;
-webkit-animation: anim-moema-1 0.3s forwards;
animation: anim-moema-1 0.3s forwards;
}
.button--moema.button--inverted:hover {
color: #ECEFF1;
background-color: #7986cb;
}
.button--moema:hover::before {
-webkit-animation: anim-moema-2 0.3s 0.3s forwards;
animation: anim-moema-2 0.3s 0.3s forwards;
}
@-webkit-keyframes anim-moema-1 {
60% {
-webkit-transform: scale3d(0.8, 0.8, 1);
transform: scale3d(0.8, 0.8, 1);
}
85% {
-webkit-transform: scale3d(1.1, 1.1, 1);
transform: scale3d(1.1, 1.1, 1);
}
100% {
-webkit-transform: scale3d(1, 1, 1);
transform: scale3d(1, 1, 1);
}
}
@keyframes anim-moema-1 {
60% {
-webkit-transform: scale3d(0.8, 0.8, 1);
transform: scale3d(0.8, 0.8, 1);
}
85% {
-webkit-transform: scale3d(1.1, 1.1, 1);
transform: scale3d(1.1, 1.1, 1);
}
100% {
-webkit-transform: scale3d(1, 1, 1);
transform: scale3d(1, 1, 1);
}
}
@-webkit-keyframes anim-moema-2 {
to {
opacity: 0;
-webkit-transform: scale3d(1, 1, 1);
transform: scale3d(1, 1, 1);
}
}
@keyframes anim-moema-2 {
to {
opacity: 0;
-webkit-transform: scale3d(1, 1, 1);
transform: scale3d(1, 1, 1);
}
}

/* Isi */
.button--isi {
padding: 1.2em 2em;
color: #fff;
background: #7986cb;
overflow: hidden;
}
.button--isi::before {
content: '';
z-index: -1;
position: absolute;
top: 50%;
left: 100%;
margin: -15px 0 0 1px;
width: 30px;
height: 30px;
border-radius: 50%;
background: #3f51b5;
-webkit-transform-origin: 100% 50%;
transform-origin: 100% 50%;
-webkit-transform: scale3d(1, 2, 1);
transform: scale3d(1, 2, 1);
-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
transition: transform 0.3s, opacity 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.7,0,0.9,1);
transition-timing-function: cubic-bezier(0.7,0,0.9,1);
}
.button--isi .button__icon {
vertical-align: middle;
}
.button--isi > span {
vertical-align: middle;
padding-left: 0.75em;
}
.button--isi:hover::before {
-webkit-transform: scale3d(9, 9, 1);
transform: scale3d(9, 9, 1);
}

/* Aylen */
.button.button--aylen {
background: #fff;
color: #37474f;
overflow: hidden;
-webkit-transition: color 0.3s;
transition: color 0.3s;
}
.button--aylen.button--inverted {
background: none;
color: #fff;
}
.button--aylen::before,
.button--aylen::after {
content: '';
position: absolute;
height: 100%;
width: 100%;
bottom: 100%;
left: 0;
z-index: -1;
-webkit-transition: -webkit-transform 0.3s;
transition: transform 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--aylen::before {
background: #7986cb;
}
.button--aylen::after {
background: #3f51b5;
}
.button--aylen:hover {
color: #fff;
}
.button--aylen:hover::before,
.button--aylen:hover::after {
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
}
.button--aylen:hover::after {
-webkit-transition-delay: 0.175s;
transition-delay: 0.175s;
}

/* Saqui */
.button.button--saqui {
overflow: hidden;
color: #fff;
background: #37474f;
-webkit-transition: background-color 0.3s ease-in, color 0.3s ease-in;
transition: background-color 0.3s ease-in, color 0.3s ease-in;
}
.button--saqui.button--inverted {
background: #fff;
color: #37474f;
}
.button--saqui::after {
content: attr(data-text);
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
padding: 1em 2em;
color: #37474f;
-webkit-transform-origin: -25% 50%;
transform-origin: -25% 50%;
-webkit-transform: rotate3d(0, 0, 1, 45deg);
transform: rotate3d(0, 0, 1, 45deg);
-webkit-transition: -webkit-transform 0.3s ease-in;
transition: transform 0.3s ease-in;
}
.button--saqui.button--inverted::after {
color: #fff;
}
.button--saqui:hover::after,
.button--saqui:hover {
-webkit-transition-timing-function: ease-out;
transition-timing-function: ease-out;
}
.button--saqui:hover {
background-color: #7986cb;
color: #7986cb;
}
.button--saqui.button--inverted:hover {
background-color: #3f51b5;
color: #3f51b5;
}
.button--saqui:hover::after {
-webkit-transform: rotate3d(0, 0, 1, 0deg);
transform: rotate3d(0, 0, 1, 0deg);
}

/* Wapasha */
.button.button--wapasha {
background: #37474f;
color: #fff;
-webkit-transition: background-color 0.3s, color 0.3s;
transition: background-color 0.3s, color 0.3s;
}
.button--wapasha.button--inverted {
background: #fff;
color: #37474f;
}
.button--wapasha::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border: 2px solid #3f51b5;
z-index: -1;
border-radius: inherit;
opacity: 0;
-webkit-transform: scale3d(0.6, 0.6, 1);
transform: scale3d(0.6, 0.6, 1);
-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
transition: transform 0.3s, opacity 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--wapasha.button--inverted::before {
border-color: #7986cb;
}
.button--wapasha:hover {
background-color: #fff;
color: #3f51b5;
}
.button--wapasha.button--inverted:hover {
background-color: #37474f;
color: #7986cb;
}
.button--wapasha:hover::before {
-webkit-transform: scale3d(1, 1, 1);
transform: scale3d(1, 1, 1);
opacity: 1;
}

/* Nina */
.button--nina {
padding: 0 2em;
background: #7986cb;
color: #fff;
overflow: hidden;
-webkit-transition: background-color 0.3s;
transition: background-color 0.3s;
}
.button--nina.button--inverted {
background: #fff;
color: #7986cb;
}
.button--nina > span {
display: inline-block;
padding: 1em 0;
opacity: 0;
color: #fff;
-webkit-transform: translate3d(0, -10px, 0);
transform: translate3d(0, -10px, 0);
-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
transition: transform 0.3s, opacity 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--nina::before {
content: attr(data-text);
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
padding: 1em 0;
-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
transition: transform 0.3s, opacity 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--nina:hover {
background-color: #3f51b5;
}
.button--nina:hover::before {
opacity: 0;
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
}
.button--nina:hover > span {
opacity: 1;
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
.button--nina:hover > span:nth-child(1) {
-webkit-transition-delay: 0.045s;
transition-delay: 0.045s;
}
.button--nina:hover > span:nth-child(2) {
-webkit-transition-delay: 0.09s;
transition-delay: 0.09s;
}
.button--nina:hover > span:nth-child(3) {
-webkit-transition-delay: 0.135s;
transition-delay: 0.135s;
}
.button--nina:hover > span:nth-child(4) {
-webkit-transition-delay: 0.18s;
transition-delay: 0.18s;
}
.button--nina:hover > span:nth-child(5) {
-webkit-transition-delay: 0.225s;
transition-delay: 0.225s;
}
.button--nina:hover > span:nth-child(6) {
-webkit-transition-delay: 0.27s;
transition-delay: 0.27s;
}
.button--nina:hover > span:nth-child(7) {
-webkit-transition-delay: 0.315s;
transition-delay: 0.315s;
}
.button--nina:hover > span:nth-child(8) {
-webkit-transition-delay: 0.36s;
transition-delay: 0.36s;
}
.button--nina:hover > span:nth-child(9) {
-webkit-transition-delay: 0.405s;
transition-delay: 0.405s;
}
.button--nina:hover > span:nth-child(10) {
-webkit-transition-delay: 0.45s;
transition-delay: 0.45s;
}

/* Nanuk */
.button--nanuk {
padding: 0 2em;
overflow: hidden;
background: #7986cb;
-webkit-transition: background-color 0.3s;
transition: background-color 0.3s;
}
.button--nanuk.button--inverted {
background: #fff;
color: #7986cb;
}
.button--nanuk > span {
display: inline-block;
padding: 1em 0;
}
.button--nanuk:hover {
background-color: #3f51b5;
}
.button--nanuk:hover > span:nth-child(odd) {
-webkit-animation: anim-nanuk-1 0.5s forwards;
animation: anim-nanuk-1 0.5s forwards;
}
.button--nanuk:hover > span:nth-child(even) {
-webkit-animation: anim-nanuk-2 0.5s forwards;
animation: anim-nanuk-2 0.5s forwards;
}
.button--nanuk:hover > span:nth-child(odd),
.button--nanuk:hover > span:nth-child(even) {
-webkit-animation-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-animation-function: cubic-bezier(0.75, 0, 0.125, 1);
}
@-webkit-keyframes anim-nanuk-1 {
0%,
100% {
opacity: 1;
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
49% {
opacity: 1;
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
}
50% {
opacity: 0;
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
color: inherit;
}
51% {
opacity: 0;
-webkit-transform: translate3d(0, -100%, 0);
transform: translate3d(0, -100%, 0);
color: #fff;
}
100% {
color: #fff;
}
}
@keyframes anim-nanuk-1 {
0%,
100% {
opacity: 1;
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
49% {
opacity: 1;
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
}
50% {
opacity: 0;
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
color: inherit;
}
51% {
opacity: 0;
-webkit-transform: translate3d(0, -100%, 0);
transform: translate3d(0, -100%, 0);
color: #fff;
}
100% {
color: #fff;
}
}
@-webkit-keyframes anim-nanuk-2 {
0%,
100% {
opacity: 1;
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
49% {
opacity: 1;
-webkit-transform: translate3d(0, -100%, 0);
transform: translate3d(0, -100%, 0);
}
50% {
opacity: 0;
-webkit-transform: translate3d(0, -100%, 0);
transform: translate3d(0, -100%, 0);
color: inherit;
}
51% {
opacity: 0;
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
color: #fff;
}
100% {
color: #fff;
}
}
@keyframes anim-nanuk-2 {
0%,
100% {
opacity: 1;
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
49% {
opacity: 1;
-webkit-transform: translate3d(0, -100%, 0);
transform: translate3d(0, -100%, 0);
}
50% {
opacity: 0;
-webkit-transform: translate3d(0, -100%, 0);
transform: translate3d(0, -100%, 0);
color: inherit;
}
51% {
opacity: 0;
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
color: #fff;
}
100% {
color: #fff;
}
}
.button--nanuk:hover > span:nth-child(1) {
-webkit-animation-delay: 0s;
animation-delay: 0s;
}
.button--nanuk:hover > span:nth-child(2) {
-webkit-animation-delay: 0.05s;
animation-delay: 0.05s;
}
.button--nanuk:hover > span:nth-child(3) {
-webkit-animation-delay: 0.1s;
animation-delay: 0.1s;
}
.button--nanuk:hover > span:nth-child(4) {
-webkit-animation-delay: 0.15s;
animation-delay: 0.15s;
}
.button--nanuk:hover > span:nth-child(5) {
-webkit-animation-delay: 0.2s;
animation-delay: 0.2s;
}
.button--nanuk:hover > span:nth-child(6) {
-webkit-animation-delay: 0.25s;
animation-delay: 0.25s;
}
.button--nanuk:hover > span:nth-child(7) {
-webkit-animation-delay: 0.3s;
animation-delay: 0.3s;
}
.button--nanuk:hover > span:nth-child(8) {
-webkit-animation-delay: 0.35s;
animation-delay: 0.35s;
}
.button--nanuk:hover > span:nth-child(9) {
-webkit-animation-delay: 0.4s;
animation-delay: 0.4s;
}
.button--nanuk:hover > span:nth-child(10) {
-webkit-animation-delay: 0.45s;
animation-delay: 0.45s;
}
.button--nanuk:hover > span:nth-child(11) {
-webkit-animation-delay: 0.5s;
animation-delay: 0.5s;
}

/* Antiman */
.button--antiman {
background: none;
border: none;
height: 60px;
}
.button--antiman.button--inverted,
.button--antiman.button--inverted-alt {
-webkit-transition: color 0.3s;
transition: color 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--antiman > span {
padding-left: 0.35em;
}
.button--antiman::before,
.button--antiman::after {
content: '';
z-index: -1;
border-radius: inherit;
pointer-events: none;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
-webkit-backface-visibility: hidden;
-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
transition: transform 0.3s, opacity 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--antiman::before {
border: 2px solid #37474f;
opacity: 0;
-webkit-transform: scale3d(1.2, 1.2, 1);
transform: scale3d(1.2, 1.2, 1);
}
.button--antiman.button--border-thin::before {
border-width: 1px;
}
.button--antiman.button--border-medium::before {
border-width: 2px;
}
.button--antiman.button--border-thick::before {
border-width: 3px;
}
.button--antiman.button--inverted::before {
border-color: #7986cb;
}
.button--antiman.button--inverted-alt::before {
border-color: #3f51b5;
}
.button--antiman::after {
background: #fff;
}
.button--antiman.button--inverted::after {
background: #7986cb;
}
.button--antiman.button--inverted-alt::after {
background: #3f51b5;
}
.button--antiman.button--inverted:hover {
color: #7986cb;
}
.button--antiman.button--inverted-alt:hover {
color: #3f51b5;
}
.button--antiman:hover::before {
opacity: 1;
-webkit-transform: scale3d(1, 1, 1);
transform: scale3d(1, 1, 1);
}
.button--antiman:hover::after {
opacity: 0;
-webkit-transform: scale3d(0.8, 0.8, 1);
transform: scale3d(0.8, 0.8, 1);
}

/* Itzel */
.button--itzel {
border: none;
padding: 0px;
overflow: hidden;
width: 255px;
}
.button--itzel::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border: 2px solid;
border-radius: inherit;
-webkit-clip-path: polygon(0% 0%, 0% 100%, 35% 100%, 35% 60%, 65% 60%, 65% 100%, 100% 100%, 100% 0%);
clip-path: url(../index.html#clipBox);
-webkit-transform: translate3d(0, 100%, 0) translate3d(0, -2px, 0);
transform: translate3d(0, 100%, 0) translate3d(0, -2px, 0);
-webkit-transform-origin: 50% 100%;
transform-origin: 50% 100%;
}

.button--itzel.button--border-thin::before {
border: 1px solid;
-webkit-transform: translate3d(0, 100%, 0) translate3d(0, -1px, 0);
transform: translate3d(0, 100%, 0) translate3d(0, -1px, 0);
}
.button--itzel.button--border-thick::before {
border: 3px solid;
-webkit-transform: translate3d(0, 100%, 0) translate3d(0, -3px, 0);
transform: translate3d(0, 100%, 0) translate3d(0, -3px, 0);
}

.button--itzel::before,
.button--itzel .button__icon {
-webkit-transition: -webkit-transform 0.3s;
transition: transform 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--itzel .button__icon {
position: absolute;
top: 100%;
left: 50%;
padding: 20px;
font-size: 20px;
-webkit-transform: translate3d(-50%, 0, 0);
transform: translate3d(-50%, 0, 0);
}
.button--itzel > span {
display: block;
padding: 20px;
-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
transition: transform 0.3s, opacity 0.3s;
-webkit-transition-delay: 0.3s;
transition-delay: 0.3s;
}
.button--itzel:hover::before {
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
.button--itzel:hover .button__icon {
-webkit-transition-delay: 0.1s;
transition-delay: 0.1s;
-webkit-transform: translate3d(-50%, -100%, 0);
transform: translate3d(-50%, -100%, 0);
}
.button--itzel:hover > span {
opacity: 0;
-webkit-transform: translate3d(0, -50%, 0);
transform: translate3d(0, -50%, 0);
-webkit-transition-delay: 0s;
transition-delay: 0s;
}

/* Naira */
.button--naira {
padding: 0;
overflow: hidden;
-webkit-transition: background-color 0.3s;
transition: background-color 0.3s;
}
.button--naira::before {
content: '';
position: absolute;
left: -50%;
width: 200%;
height: 200%;
background: #37474f;
top: -50%;
z-index: -1;
-webkit-transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -10deg);
transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -10deg);
}
.button--naira.button--inverted::before {
background: #7986cb;
}
.button--naira-up::before {
-webkit-transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 10deg);
transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 10deg);
}
.button--naira > span {
display: block;
}
.button--naira .button__icon {
position: absolute;
top: 0;
width: 100%;
left: 0;
color: #fff;
-webkit-transform: translate3d(0, -100%, 0);
transform: translate3d(0, -100%, 0);
}
.button--naira-up .button__icon {
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
}
.button--naira > span,
.button--naira .button__icon {
padding: 1em 2em;
-webkit-transition: -webkit-transform 0.3s;
transition: transform 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--naira:hover::before {
-webkit-animation: anim-naira-1 0.3s forwards ease-in;
animation: anim-naira-1 0.3s forwards ease-in;
}
.button--naira-up:hover::before {
-webkit-animation: anim-naira-2 0.3s forwards ease-in;
animation: anim-naira-2 0.3s forwards ease-in;
}
@-webkit-keyframes anim-naira-1 {
50% {
-webkit-transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
-webkit-animation-timing-function: ease-out;
animation-timing-function: ease-out;
}
100% {
-webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
}
}
@keyframes anim-naira-1 {
50% {
-webkit-transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
-webkit-animation-timing-function: ease-out;
animation-timing-function: ease-out;
}
100% {
-webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
}
}
@-webkit-keyframes anim-naira-2 {
50% {
-webkit-transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
-webkit-animation-timing-function: ease-out;
animation-timing-function: ease-out;
}
100% {
-webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
}
}
@keyframes anim-naira-2 {
50% {
-webkit-transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
-webkit-animation-timing-function: ease-out;
animation-timing-function: ease-out;
}
100% {
-webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
}
}
.button--naira:hover {
background-color: #37474f;
-webkit-transition: background-color 0s 0.3s;
transition: background-color 0s 0.3s;
}
.button--naira.button--inverted:hover {
background-color: #7986cb;
}
.button--naira:hover .button__icon {
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
.button--naira:hover > span {
opacity: 0;
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
}
.button--naira-up:hover > span {
-webkit-transform: translate3d(0, -100%, 0);
transform: translate3d(0, -100%, 0);
}

/* Quidel */
.button--quidel {
background: #7986cb;
color: #7986cb;
overflow: hidden;
-webkit-transition: color 0.3s;
transition: color 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--quidel.button--inverted {
background: #fff;
color: #fff;
}
.button--quidel::before,
.button--quidel::after {
content: '';
position: absolute;
z-index: -1;
border-radius: inherit;
}
.button--quidel::after {
top: 2px;
left: 2px;
right: 2px;
bottom: 2px;
background: #fff;
}
.button--quidel.button--inverted::after {
background: #37474f;
}
.button--quidel::before {
background: #37474f;
top: 0;
left: 0;
width: 100%;
height: 100%;
-webkit-transform: translate3d(0, 100%, 0);
transform: translate3d(0, 100%, 0);
-webkit-transition: -webkit-transform 0.3s;
transition: transform 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--quidel.button--inverted::before {
background: #7986cb;
}
.button--round-s.button--quidel::after {
border-radius: 3px;
}
.button--round-m.button--quidel::after {
border-radius: 13px;
}
.button--round-l.button--quidel::after {
border-radius: 40px;
}
.button--quidel > span {
padding-left: 0.35em;
}
.button--quidel:hover {
color: #37474f;
}
.button--quidel.button--inverted:hover {
color: #7986cb;
}
.button--quidel:hover::before {
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}

/* Sacnite */
.button.button--sacnite {
width: 70px;
height: 70px;
min-width: 0;
padding: 0;
color: #fff;
-webkit-transition: color 0.3s;
transition: color 0.3s;
}
.button--scanite.button--round-l {
border-radius: 50%;
}
.button--sacnite.button--inverted {
color: #37474f;
}
.button--sacnite::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border-radius: inherit;
z-index: -1;
box-shadow: inset 0 0 0 35px #37474f;
-webkit-transform: scale3d(0.9, 0.9, 1);
transform: scale3d(0.9, 0.9, 1);
-webkit-transition: box-shadow 0.3s, -webkit-transform 0.3;
transition: box-shadow 0.3s, transform 0.3s;
}
.button--sacnite.button--inverted::before {
box-shadow: inset 0 0 0 35px #fff;
}
.button--sacnite .button__icon {
font-size: 22px;
width: 22px;
}
.button--sacnite > span {
position: absolute;
opacity: 0;
pointer-events: none;
}
.button--sacnite:hover {
color: #37474f;
}
.button--sacnite.button--inverted:hover {
color: #fff;
}
.button--sacnite:hover::before {
box-shadow: inset 0 0 0 2px #37474f;
-webkit-transform: scale3d(1, 1, 1);
transform: scale3d(1, 1, 1);
}
.button--sacnite.button--inverted:hover::before {
box-shadow: inset 0 0 0 2px #fff;
}

/* Shikoba */
.button.button--shikoba {
padding: 1em 30px 1em 50px;
overflow: hidden;
background: #fff !important;
color: #37474f;
color: #175298 !important;
border-color: #37474f;
border-color: #175298 !important;
-webkit-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.button--shikoba.button--inverted {
color: #fff !important;
background: #7986cb;
background: #175298 !important;
}
.button--shikoba.button--empty {

border-color: white !important;

}
.button--shikoba.button--empty:hover {
color: #fff !important;
background: #7986cb;
background: #175298 !important;
border-color: white !important;
background: transparent !important;
}
.button--shikoba > span {
display: inline-block;
-webkit-transform: translate3d(-10px, 0, 0);
transform: translate3d(-10px, 0, 0);
-webkit-transition: -webkit-transform 0.3s;
transition: transform 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--shikoba .button__icon {
position: absolute;
left: 20px;
font-size: 18px !important;
-webkit-transform: translate3d(-40px, 2.5em, 0);
transform: translate3d(-40px, 2.5em, 0);
-webkit-transition: -webkit-transform 0.3s;
transition: transform 0.3s;
-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--shikoba .button__icon.upleft {

-webkit-transform: translate3d(-40px, -2.5em, 0);
transform: translate3d(-40px, -2.5em, 0);

}
.button--shikoba .button__icon.left {

-webkit-transform: translate3d(-40px, 0, 0);
transform: translate3d(-40px, 0, 0);

}
.button--shikoba:hover .button__icon,
.button--shikoba:hover > span {
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
.button--shikoba:hover {
background: #3f51b5;
background:  #175298 !important;
border-color: #3f51b5;
border-color: #175298 !important;
color: #fff !important;
}




/***************END**************/