$fs1: 5.25rem; //84px
$fs2: 3.5rem; //56px
$fs3: 2rem; //30px
$fs4: 1.5rem; //24px
$fs5: 1.25rem; //20px
$fs6: 1rem; //16px
$fs7: 0.72rem; //12px



$prefix: fs;

$font-list: (
  1 84 84,
  2 72 74,
  3 56 60,
  4 44 48,
  5 30 36,
  6 24 26,
  7 20 24,
  8 18 18,
  9 12 14
);

@each $value in $font-list {
  .#{$prefix}-#{nth($value, 1)} {
      font-size: (nth($value, 2)/16) + em;
  }	
}

@media (max-width: 960px) and (min-width: 641px) {
	@each $value in $font-list {
	  .#{$prefix}-#{nth($value, 1)} {
	      font-size: (nth($value, 2)*0.8/16) + em;
	  }	
	}
}
@media (max-width: 640px) {
	@each $value in $font-list {
	  .#{$prefix}-#{nth($value, 1)} {
	      font-size: (nth($value, 3)*0.6/16) + em;
	  }	
	}
}

p{
  margin-bottom: 1em;
  line-height: 1.4;

}

.lh-10{
  line-height: 1;
}
.lh-11{
  line-height: 1.1;
}
.lh-12{
  line-height: 1.2;
}
.lh-13{
  line-height: 1.3;
}
.lh-14{
  line-height: 1.4;
}
.lh-15{
  line-height: 1.5;
}
.lh-16{
  line-height: 1.6;
}
.lh-17{
  line-height: 1.7;
}
.lh-18{
  line-height: 1.8;
}
.lh-19{
  line-height: 1.9;
}
.lh-20{
  line-height: 2;
}

.mb-00{
  margin-bottom: 0;
}
.mb-02{
  margin-bottom: 0.2em;
}
.mb-03{
  margin-bottom: 0.3em;
}
.mb-05{
  margin-bottom: 0.5em;
}
.mb-10{
  margin-bottom: 1em;
}
.mb-15{
  margin-bottom: 1.5em;
}
.mb-20{
  margin-bottom: 2em;
}

.fw-1{font-weight:100;}
.fw-3{font-weight:300;}
.fw-4{font-weight:400;}
.fw-5{font-weight:500;}
.fw-6{font-weight:600;}
.fw-7{font-weight:700;}

ul {
	margin-left: 0;

	li {
		list-style: none;
	}
}